
import { mapState, mapMutations, mapActions } from 'vuex'

import NavigationIcon from '@pleinair/bigcolony-vue/components/_globals/vectors/NavigationIcon.vue'
import LocationPhoneInfo from '@pleinair/bigcolony-vue/components/locations/search/elements/PhoneInfo.vue'
import LocationAddressInfo from '@pleinair/bigcolony-vue/components/locations/search/elements/AddressInfo.vue'
import LocationOperationInfo from '../locations/LocationOperationInfo.vue'
import VehicleInfo from '@pleinair/bigcolony-vue/components/locations/search/elements/VehicleInfo.vue'
import Icon from '@pleinair/bigcolony-vue/components/common/Icon/index.vue'

export default {
  name: 'LocationSelected',
  components: {
    LocationOperationInfo,
    LocationAddressInfo,
    LocationPhoneInfo,
    NavigationIcon,
    VehicleInfo,
    Icon,
  },
  props: {
    data: {
      type: Object,
      default: () => { }
    },
    title: {
      type: String,
      default: '',
    },
    icon: {
      type: Object,
      default: () => { },
    },
    action: {
      type: Object,
      default: () => { },
    },
    config: {
      type: Object,
      default: () => {
        return {
          separator: '|',
          showServicesOnCard: false,
          showServicesOnOperation: true,
          locationPageAction: '',
          locationPageIcon: '',
          favoriteEnabled: false
        }
      }
    },
  },
  data() {
    return {
      brands: [],
    }
  },
  async mounted() {
    this.separateBrands()

    let config = {
      method: 'get',
      url: '/locations?status=true',
    }

    let locStatus = await this.sendRequest(config)

    let location = null
    if (locStatus.status === 'ok') {
      location = JSON.parse(JSON.stringify(this.location))
      locStatus.data.locations.map(loc => {
        if (loc.id === location.id) {
          location.open = loc.open
        }
      })
    }

    if (location) {
      this.setLocation(location)
    }
  },
  computed: {
    ...mapState(['user', 'location', 'appInfo']),
    ...mapState({
      handoffMode: state => state.order.handoffMode,
      favLocs: state => state.members.favLocs,
    }),
    services() {
      if (this.config && this.config.showServicesOnCard && this.location && this.location.services) {
        const services = []
        if (this.location.services.delivery) {
          services.push({
            name: 'Delivery',
            iconName: 'delivery-icon',
            iconClasses: 'delivery-icon',
          })
        }
        if (this.location.services.pickup) {
          services.push({
            name: 'Pickup',
            iconName: 'pickup-icon',
            iconClasses: 'pickup-icon',
          })
        }
        if (this.location.services.curbside) {
          services.push({
            name: 'Curbside',
            iconName: 'curbside-icon',
            iconClasses: 'curbside-icon',
          })
        }
        if (this.location.services.drivethru) {
          services.push({
            name: 'Drive Thru',
            iconName: 'curbside-icon',
            iconClasses: 'curbside-icon',
          })
        }
        return services
      }

      return []
    },
    multibrandCategories() {
      let categories = [];
      this.config?.multibrand?.brands.map((m) => {
        Object.keys(this.location).map((k) => {
          if (k === m.key && this.location[k] === true) {
            categories.push(m);
          }
        });
      });
      return categories;
    },
  },

  methods: {
    ...mapActions([ 'sendRequest', 'trackEvent' ]),
    ...mapActions({
      saveFavLoc: 'members/saveFavLoc',
      deleteFavLoc: 'members/deleteFavLoc',
    }),
    ...mapMutations([ 'setLocation' ]),
    async favorite() {
      const favLocFound = this.favLocs.find(l => l.id === this.location.id)
      if (favLocFound) {
        await this.deleteFavLoc(this.location.menu_id)
      } else {
        const response = await this.saveFavLoc(this.location.menu_id)
        if (response) {
          this.trackEvent({
            name: 'location_favorited',
            data: this.location,
            windowObject: window
          })
        }
      }
    },
    separateBrands() {
      this.brands = [];

      if (this.multibrandCategories.length > 0) {
        let brands = [];
        this.multibrandCategories.map((c) => {
          if (c.brand !== this.appInfo?.name) {
            let brandImage = c?.logo;

            // Push brand
            const brand = {
              name: c.brand,
              category: c.brand,
              image: brandImage ? brandImage : "",
            };
            brands.push(brand);
          }
        });
        this.brands = brands;
      }
    },
  }
}
