
import moment from 'moment'

export default {
  name: 'LocationOperationInfo',
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    separator: {
      type: String,
      default: '•'
    },
    showServices: {
      type: Boolean,
      deafult: true,
    }
  },
  data() {
    return {
      tomorrowDay: new Date().getDay() + 1,
      currentDay: new Date().getDay(),
      daysOfWeek: ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'],
    }
  },
  computed: {
    services() {
      if (this.showServices && this.data && this.data.services) {
        const services = []
        if (this.data.services.delivery) {
          services.push('Delivery')
        }
        if (this.data.services.pickup) {
          services.push('Pickup')
        }
        if (this.data.services.curbside) {
          services.push('Curbside')
        }
        if (this.data.services.drivethru) {
          services.push('Drive Thru')
        }
        return services.join(' & ')
      }

      return ''
    },
    todayOpeningHour() {
      const currentDayHours = this.getDayOpeningHour(this.currentDay); 
      if (currentDayHours?.open) {
        return this.formatTime(currentDayHours.open)
      }
    },
    todayCloseningHour() {
      const currentDayHours = this.getDayOpeningHour(this.currentDay);
      if (currentDayHours?.close) {
        return this.formatTime(currentDayHours.close)
      }
    },
    tomorrowOpeningHour() {
      const tomorrowHours = this.getDayOpeningHour(this.tomorrowDay);
      if(tomorrowHours?.open) {
        return this.formatTime(tomorrowHours.open)
      }
    },
    isClosing () {
      const currentDayHours = this.getDayOpeningHour(this.currentDay)
      const currentHour = parseInt(this.currentHour)
      const closingHour = currentDayHours?.close
        ? parseInt(currentDayHours.close.split(':')[0])
        : 0

      return currentHour === closingHour - 1
    },
    isOpening () {
      if (!this.getDayOpeningHour(this.currentDay)) return false
      const currentHour = parseInt(this.currentHour)
      const openingHour = this.getDayOpeningHour(this.currentDay)?.open
        ? parseInt(this.getDayOpeningHour(this.currentDay).open.split(':')[0])
        : 0

      return currentHour === openingHour - 1
    },
    openingTime() {
      const currentHour = parseInt(this.currentHour)
      const closingHour = this.getDayOpeningHour(this.currentDay)?.close
        ? parseInt(this.getDayOpeningHour(this.currentDay)?.close.split(':')[0])
        : 0

      if (currentHour > closingHour) {
        return this.tomorrowOpeningHour
      } else {
        return this.todayOpeningHour
      }
    },
    isOpen() {
      // Always trust the open data following update to call /location?status=true in default all locations view.
      return this.data.open

      
      // const dayOfWeek = moment().format('YYYYMMDD')

      // const hours = this.data.hours.find(h => h.date === dayOfWeek)

      // if (hours !== undefined) {
      //   const now = this.nowTimeAtLocation
      //   const open = moment(hours.open, 'HH:mm')
      //   let close = moment(hours.close, 'HH:mm')

      //   // if close time is after midnight of the next day, add a day to close
      //   if (close <= open) {
      //     close = close.add(1, 'day')
      //   }

      //   const isOpen = now > open && now < close
      //   return isOpen
      // }
    },
    nowTimeAtLocation() {
      let loc = this.data
      const localUtcOffset = moment().utcOffset() / 60
      const locationUtcOffset = loc.utcoffset || loc.utcOffset
      const absoluteLocalUtcOffset = Math.abs(localUtcOffset)
      const absoluteLocationUtcOffset = Math.abs(locationUtcOffset)
      const difference = Math.abs(localUtcOffset - locationUtcOffset)

      let now = moment()
      if (absoluteLocalUtcOffset < absoluteLocationUtcOffset) {
        // local time is ahead
        now = moment().subtract(difference, 'hours')
      } else if (absoluteLocalUtcOffset > absoluteLocationUtcOffset) {
        // local time is behind
        now = moment().add(difference, 'hours')
      }

      return now
    },
    currentHour() {
      return new Date(this.nowTimeAtLocation).getHours()
    },
    currentDistance() {
      try {
        return this.data.distance.toFixed(2)
      } catch (e) {
        return this.data.distance
      }
    }
  },
  methods: {
    formatTime(time) {
      return moment(time, 'HH:mm').format('h:mm A');
    },
    getDayOpeningHour(day) {
      if (this.data && this.data.hours) {
        return this.data.hours.find(
          (hour) => hour.day === this.daysOfWeek[day]
        );
      }
    },
    getTime(time) {
      return moment(time, 'h:mm').format('h:mm A')
    }
  },
} 
